import api from '../index'

export interface captchaModel {
  password: string
  username: string
  verifyCodeKey: string
  verifyCodeValue: string
}
export interface loginParamsType {
  clientId?: string
  grantType?: string
  clientSecret?: number
  captchaModel: captchaModel
}

export interface codeResponse {
  captchaEnabled: boolean
  code: number
  img: string
  msg: string
  uuid: string
}
interface RootObject {
  msg: string
  code: number
  permissions: string[]
  roles: string[]
  data: User
}

interface User {
  createBy: string
  createTime: string
  updateBy: null
  updateTime: null
  remark: string
  userId: number
  deptId: number
  userName: string
  nickName: string
  email: string
  phonenumber: string
  sex: string
  avatar: string
  password: string
  status: string
  delFlag: string
  loginIp: string
  loginDate: string
  dept: Dept
  roles: Role[]
  roleIds: null
  postIds: null
  roleId: null
  admin: boolean
}

interface Role {
  createBy: null
  createTime: null
  updateBy: null
  updateTime: null
  remark: null
  roleId: number
  roleName: string
  roleKey: string
  roleSort: number
  dataScope: string
  menuCheckStrictly: boolean
  deptCheckStrictly: boolean
  status: string
  delFlag: null
  flag: boolean
  menuIds: null
  deptIds: null
  permissions: null
  admin: boolean
}

interface Dept {
  createBy: null
  createTime: null
  updateBy: null
  updateTime: null
  remark: null
  deptId: number
  parentId: null
  ancestors: null
  deptName: null
  orderNum: null
  leader: null
  phone: null
  email: null
  status: null
  delFlag: null
  parentName: null
  children: any[]
}

export default {
  // 登录
  login: (data: loginParamsType) => api.post('/auth/oauth/token', data),

  getInfo: (): Promise<RootObject> => api.get('/tiezi/back/sys/user/getInfo'),

  getCode: (): Promise<codeResponse> => api.get('/code'),

  logout: () => api.delete('/auth/oauth/logout'),
}
